import {
  createAsyncThunk,
  createSelector,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { container } from "tsyringe";
import { listenerMiddleware } from "../app/listenerMiddleware";
import type { RootState } from "../app/store";
import { Product } from "../db/model/product";
import { Sale, saleTemplate } from "../db/model/sale";
import { SaleLine, saleLineTemplate } from "../db/model/sale-line";
import { SaleService } from "./sale.service";
import moment from "moment";

export interface SaleState {
  sales: Sale[];
  activeLineId: string | null;
  activeSaleId: string | null;
  uploadStatus: "idle" | "pending" | "rejected";
}

const initialState: SaleState = {
  activeLineId: null,
  activeSaleId: null,
  sales: [],
  uploadStatus: "idle",
};

export const appSlice = createSlice({
  name: "sale",
  initialState,
  reducers: {
    addSaleLine: (state, action: PayloadAction<Product>) => {
      saveSaleLine(
        state,
        state.activeSaleId,
        action.payload._id,
        (_sale, line) =>
          !line
            ? saleLineTemplate(action.payload)
            : { ...line, quantity: line.quantity + 1 }
      );
    },
    clearActiveLine: (state) => {
      state.activeLineId = null;
    },
    closeActiveSale: (state) => {
      patchSale(state, state.activeSaleId, (sale) => ({
        ...sale,
        closed: moment().unix(),
        status: "closed",
      }));
      state.activeSaleId = null;
    },
    deleteAllSaleLines: (state) => {
      state.activeLineId = null;
      patchSale(state, state.activeSaleId, (sale) => ({
        ...sale,
        lines: [],
      }));
    },
    deleteSale: (state, action: PayloadAction<string>) => {
      state.sales = state.sales.filter((sale) => sale._id !== action.payload);

      if (action.payload === state.activeSaleId) {
        state.activeSaleId = null;
      }
    },
    deleteSaleLine: (state, action: PayloadAction<string | undefined>) => {
      const saleLineId = action.payload ?? state.activeLineId;

      patchSale(state, state.activeSaleId, (sale) => ({
        ...sale,
        lines: sale.lines.filter((line) => line.id !== saleLineId),
      }));

      if (state.activeLineId === saleLineId) {
        state.activeLineId = null;
      }
    },
    setActiveLine: (state, action: PayloadAction<string>) => {
      state.activeLineId = action.payload;
    },
    setActiveSale: (state, action: PayloadAction<string>) => {
      state.activeSaleId = action.payload;
    },
    startNewSale: (state) => {
      state.sales = state.sales.filter((sale) => sale.status !== "open");

      const newSale = saleTemplate();

      state.sales.push(newSale);

      state.activeSaleId = newSale._id;
    },
    updateSale: (state, action: PayloadAction<Sale>) => {
      patchSale(state, action.payload._id, (sale) => action.payload);
    },
    updateSaleLine: (state, action: PayloadAction<SaleLine>) => {
      patchSaleLine(
        state,
        state.activeSaleId,
        action.payload.id,
        (sale, line) => action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadSales.pending, (state) => {
      state.uploadStatus = "pending";
    });
    builder.addCase(uploadSales.fulfilled, (state) => {
      state.uploadStatus = "idle";
    });
    builder.addCase(uploadSales.rejected, (state) => {
      state.uploadStatus = "rejected";
    });
  },
});

export const {
  addSaleLine,
  clearActiveLine,
  closeActiveSale,
  deleteAllSaleLines,
  deleteSale,
  deleteSaleLine,
  setActiveLine,
  setActiveSale,
  startNewSale,
  updateSale,
  updateSaleLine,
} = appSlice.actions;

export const selectActiveSale = (state: RootState) =>
  state.sale.sales.find((sale) => sale._id === state.sale.activeSaleId);

export const selectActiveLine = (state: RootState) =>
  selectActiveSale(state)?.lines.find(
    (line) => line.id === state.sale.activeLineId
  );

const selectSales = (state: RootState) => state.sale.sales;

export const selectClosedSales = createSelector(selectSales, (sales) =>
  sales.filter((sale) => sale.status !== "open")
);

export const selectOfflineSales = createSelector(selectSales, (sales) =>
  sales.filter(
    (sale) => sale.status === "closed" || sale.status === "uploading"
  )
);

export const selectOpenSale = (state: RootState) =>
  state.sale.sales.find((sale) => sale.status === "open");

export const selectUploadStatus = (state: RootState) => state.sale.uploadStatus;

export default appSlice.reducer;

const patchSale = (
  state: SaleState,
  saleId: string | null,
  callback: (sale: Sale) => Sale
) => {
  state.sales = state.sales.map((sale) =>
    sale._id === saleId ? callback(sale) : sale
  );
};

const patchSaleLine = (
  state: SaleState,
  saleId: string | null,
  saleLineId: string,
  callback: (sale: Sale, line: SaleLine) => SaleLine
) => {
  patchSale(state, saleId, (sale) => ({
    ...sale,
    lines: sale.lines.map((line) =>
      line.id === saleLineId ? callback(sale, line) : line
    ),
  }));
};

const saveSaleLine = (
  state: SaleState,
  saleId: string | null,
  saleLineId: string,
  callback: (sale: Sale, line: SaleLine | null) => SaleLine
) => {
  const lineAlreadyExists = !!state.sales
    .find((sale) => sale._id === saleId)
    ?.lines.find((line) => line.id === saleLineId);

  if (lineAlreadyExists) {
    patchSaleLine(state, saleId, saleLineId, callback);
  } else {
    patchSale(state, saleId, (sale) => {
      return {
        ...sale,
        lines: [...sale.lines, callback(sale, null)],
      };
    });
  }
};

export const uploadSales = createAsyncThunk(
  "sale/uploadSales",
  async (_arg, { dispatch, getState }) => {
    const sales = selectOfflineSales(getState() as RootState);

    for (const sale of sales) {
      dispatch(
        updateSale({
          ...sale,
          status: "uploading",
        })
      );

      await new Promise((resolve) => setTimeout(resolve, 2000));

      const updatedSale = await container.resolve(SaleService).uploadSale(sale);

      dispatch(updateSale(updatedSale));
    }
  }
);

listenerMiddleware.startListening({
  type: "persist/REHYDRATE",
  effect: (_action, api) => {
    if (!selectActiveSale(api.getState() as RootState)) {
      api.dispatch(startNewSale());
    }
  },
});

listenerMiddleware.startListening({
  matcher: isAnyOf(closeActiveSale, deleteSale),
  effect: (_action, api) => {
    if (!!selectActiveSale(api.getState() as RootState)) {
      return;
    }

    const openSale = selectOpenSale(api.getState() as RootState);

    if (!!openSale) {
      api.dispatch(setActiveSale(openSale._id));
    } else {
      api.dispatch(startNewSale());
    }
  },
});

listenerMiddleware.startListening({
  matcher: isAnyOf(
    addSaleLine,
    deleteAllSaleLines,
    deleteSaleLine,
    updateSaleLine
  ),
  effect: (_action, api) => {
    const sale = selectActiveSale(api.getState() as RootState);

    if (sale?.status === "open") {
      const totalAmount = sale.lines.reduce(
        (total, line) => total + line.price * line.quantity,
        0
      );

      const totalQuantity = sale.lines.reduce(
        (total, line) => total + line.quantity,
        0
      );

      const updatedSale = {
        ...sale,
        totalAmount,
        totalQuantity,
      } as Sale;

      api.dispatch(updateSale(updatedSale));
    }
  },
});
