import { singleton } from "tsyringe";
import { Repository } from "./repository";
import { Category } from "../model/category";

@singleton()
export class CategoryRepository extends Repository<Category> {
  constructor() {
    super("categories");
  }


}
