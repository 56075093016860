import { Chip } from "@mui/material";
import { RiRefreshFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { selectOfflineSales, selectUploadStatus } from "../sale/sale.slice";
import { useResponsive } from "../theme/hooks/useResponsive";

interface Props {
  setBottomNavValue?: (value: number) => void;
}

export default function ClosedSaleIndicator({ setBottomNavValue }: Props) {
  const offlineSales = useAppSelector(selectOfflineSales);
  const uploadStatus = useAppSelector(selectUploadStatus);
  const isDesktop = useResponsive();

  if (offlineSales.length === 0) {
    return <div className="h-6" />;
  }
  return (
    <div>
      <div className="text-xs text-white flex items-center gap-1 h-6">
        {uploadStatus === "pending" ? (
          <RiRefreshFill className="animate-spin" size={18} />
        ) : (
          <Chip
            label={offlineSales.length}
            color="error"
            size="small"
            className="scale-75"
          />
        )}
        {isDesktop && (
          <NavLink className="underline" to="/list">
            offline sales to upload
          </NavLink>
        )}
        {!isDesktop && (
          <button className="underline" onClick={() => setBottomNavValue!(2)}>
            offline sales to upload
          </button>
        )}
      </div>
    </div>
  );
}
