import { singleton } from "tsyringe";
import { Setting } from "../model/setting";
import { Repository } from "./repository";

@singleton()
export class SettingRepository extends Repository<Setting> {
  constructor() {
    super("settings");
  }

  async findSettingValue<T extends string | number>(
    key: string,
    defaultValue: T | null = null
  ): Promise<T | null> {
    const setting = await this.find(key);

    if (!setting) {
      return defaultValue;
    }

    return setting.value as T;
  }

  async findAndIncremenetSettingValue(key: string): Promise<number> {
    let setting = await this.find(key);

    if (!setting) {
      setting = {
        _id: key,
        value: 0,
      };
    }

    const value = setting.value as number;

    const updatedSetting = {
      ...setting,
      value: value + 1,
    };

    await this.upsert(updatedSetting);

    return value + 1;
  }
}
