import axios from "axios";
import { injectable } from "tsyringe";
import { Sale } from "../db/model/sale";
import moment from "moment";

@injectable()
export class PredictivClient {
  appendBaseUrl(path: string): string {
    return (
      `${process.env.REACT_APP_BFF_BASE_URL}/org.openbravo.service.json.jsonrest/` +
      path
    );
  }

  async getProducts(): Promise<any[]> {
    const response = await axios.get(this.appendBaseUrl("Product"), {
      headers: this.getHeaders(),
    });

    return response.data.response.data;
  }

  async getPricelist(): Promise<any[]> {
    const response = await axios.get(
      this.appendBaseUrl("PricingProductPrice"),
      { headers: this.getHeaders() }
    );

    return response.data.response.data;
  }

  async uploadSale(sale: Sale): Promise<Sale> {
    const body = {
      data: [
        {
          _entityName: "Order",
          orderDate: moment.unix(sale.closed!).format("YYYY-MM-DD"),
          accountingDate: moment.unix(sale.closed!).format("YYYY-MM-DD"),
        },
      ],
    };

    const orderResponse = await axios.post(this.appendBaseUrl("Order"), body, {
      headers: this.getHeaders(),
    });

    if (!!orderResponse.data?.response?.error) {
      throw new Error(orderResponse.data.response.error.message);
    }

    const order = orderResponse.data.response.data[0];

    let lineNo = 1;

    const lines = sale.lines.map((line) => ({
      _entityName: "OrderLine",
      salesOrder: order.id,
      lineNo: lineNo++,
      orderDate: moment.unix(sale.closed!).format("YYYY-MM-DD"),
      product: line.product._id,
      orderedQuantity: line.quantity,
      operativeQuantity: line.quantity,
      unitPrice: line.price,
      listPrice: line.price,
      baseUnitPrice: line.price,
      uOM: line.uom,
    }));

    const linesBody = {
      data: lines,
    };

    const linesResponse = await axios.post(
      this.appendBaseUrl("OrderLine"),
      linesBody,
      {
        headers: this.getHeaders(),
      }
    );

    if (!!linesResponse.data?.response?.error) {
      throw new Error(linesResponse.data.response.error.message);
    }

    return sale;
  }

  private getHeaders(): any {
    const username = process.env.REACT_APP_BFF_USERNAME;

    const password = process.env.REACT_APP_BFF_PASSWORD;

    const token = btoa(`${username}:${password}`);
    return {
      Authorization: `Basic ${token}`,
    };
  }
}
