import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";

interface AppState {
  isUpdateAvailable: boolean;
}

const initialState: AppState = {
  isUpdateAvailable: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateAvailable: (state) => {
      state.isUpdateAvailable = true;
    },
  },
});

export const { updateAvailable } = appSlice.actions;

export const selectUpdateAvailable = (state: RootState) =>
  state.app.isUpdateAvailable;

export default appSlice.reducer;
