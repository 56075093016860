import { Chip } from "@mui/material";
import { RiRefreshFill } from "react-icons/ri";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  selectOfflineSales,
  selectUploadStatus,
  uploadSales,
} from "./sale.slice";

export default function UploadButton() {
  const offlineSales = useAppSelector(selectOfflineSales);
  const uploadStatus = useAppSelector(selectUploadStatus);

  const dispatch = useAppDispatch();

  if (offlineSales.length === 0) {
    return <div />;
  }

  const handleUploadClick = () => {
    dispatch(uploadSales());
  };

  if (uploadStatus === "pending") {
    return (
      <button disabled className="flex items-center px-4 mx-1 py-1">
        <RiRefreshFill className="animate-spin" size={18} />
        <span className="ml-1">Uploading...</span>
      </button>
    );
  }

  return (
    <button
      className="flex items-center border border-black rounded px-4 py-1"
      onClick={() => handleUploadClick()}
    >
      <Chip
        label={offlineSales.length}
        color="error"
        size="small"
        className="scale-75"
      />
      <span className="ml-1">Upload Now</span>
    </button>
  );
}
