import { Chip } from "@mui/material";
import moment from "moment";
import { FaCheckCircle } from "react-icons/fa";
import { RiRefreshFill } from "react-icons/ri";
import { useAppDispatch } from "../app/hooks";
import { Sale } from "../db/model/sale";
import { setActiveSale } from "./sale.slice";
import { MdError } from "react-icons/md";

type Props = {
  sale: Sale;
  setBottomNavValue?: (value: number) => void;
};

export default function SaleTile({ sale, setBottomNavValue }: Props) {
  const handleClickSale = (sale: Sale) => {
    dispatch(setActiveSale(sale._id));
    if (!!setBottomNavValue) {
      setBottomNavValue(0);
    }
  };

  const dispatch = useAppDispatch();

  return (
    <button onClick={() => handleClickSale(sale)} className="mb-4">
      <div className="flex grow font-mono">
        <div className="flex flex-col grow text-start">
          <div className="flex items-center gap-2">
            {sale.status === "closed" && !sale.errorMessage && (
              <Chip label="offline" color="warning" size="small" />
            )}
            {sale.status === "closed" && !!sale.errorMessage && (
              <MdError size={20} color="red" />
            )}
            {sale.status === "uploading" && (
              <RiRefreshFill className="animate-spin" size={20} />
            )}
            {sale.status === "uploaded" && (
              <FaCheckCircle size={20} color="green" />
            )}
            <div className="lg:text-lg">Cash Customer</div>
          </div>
          <div className="text-xs lg:text-sm">
            {sale.closed
              ? moment.unix(sale.closed).format("D MMM YY - HH:mm:ss")
              : "?"}
          </div>
        </div>
        <div className="flex flex-col grow text-end">
          <div className="lg:text-lg">
            {sale.totalAmount?.toFixed(2) ?? "?"}
          </div>
          <div className="text-xs lg:text-sm">{sale.paymentMethod ?? "?"}</div>
        </div>
      </div>
      {sale.status === "closed" && !!sale.errorMessage && (
        <div className="text-xs text-red-500 mt-1 col-span-2 text-start">
          {sale.errorMessage}
        </div>
      )}
    </button>
  );
}
