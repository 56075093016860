import { Product } from "./product";

export interface SaleLine {
  readonly id: string;
  readonly quantity: number;
  readonly product: Product;
  readonly price: number;
  readonly uom: string;
}

export const saleLineTemplate: (product: Product) => SaleLine = (
  product: Product
) => {
  return {
    id: product._id,
    quantity: 1,
    product,
    price: product.price,
    uom: product.uom,
  };
};
