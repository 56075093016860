import { Route, Routes } from "react-router-dom";
import "./App.css";
import { BrowsePage } from "./browse/BrowsePage";
import Layout from "./home/Layout";
import SaleListPage from "./sale/SaleListPage";

function App() {
  return (
    <div className="App bg-black">
      <Layout>
        <Routes>
          <Route path="/" element={<BrowsePage />} />
          <Route path="/list" element={<SaleListPage />} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
