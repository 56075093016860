import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

interface Props {
  content: string;
  className: string;
  active?: boolean;
  to: string;
  onClick?: () => void;
}

export const NavbarLink = ({
  content = "Browse",
  className = "",
  to,
  onClick
}: Props): JSX.Element => {
  return (
    <NavLink
      className={({ isActive }) =>
        `${className} flex items-center justify-center uppercase ${
          isActive ? "bg-[#95BFFF]" : "bg-[#d7d7d7]"
        }`
      }
      to={to}
      onClick={onClick}
    >
      {content}
    </NavLink>
  );
};

NavbarLink.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.bool,
};
