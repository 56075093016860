import { container, injectable } from "tsyringe";
import { Product } from "../db/model/product";
import { Sale } from "../db/model/sale";
import { SaleLine } from "../db/model/sale-line";
import { SaleRepository } from "../db/repository/sale.repository";
import { SettingRepository } from "../db/repository/setting.repository";
import { PredictivClient } from "../predictiv/predictiv-client";
import moment from "moment";

@injectable()
export class SaleService {
  private client: PredictivClient;
  private saleRepository: SaleRepository;
  private settingRepository: SettingRepository;

  constructor() {
    this.client = container.resolve(PredictivClient);
    this.saleRepository = container.resolve(SaleRepository);
    this.settingRepository = container.resolve(SettingRepository);
  }

  async addLine(sale: Sale, product: Product): Promise<Sale> {
    const existingLine = sale.lines.find(
      (line) => line.product._id === product._id
    );

    if (existingLine) {
      const updatedLine = {
        ...existingLine,
        quantity: existingLine.quantity + 1,
      };

      return this.replaceLine(sale, updatedLine);
    }

    const newLine = {
      id: product._id,
      quantity: 1,
      product,
      price: product.price,
    } as SaleLine;

    const newLines = [...sale.lines, newLine];

    const newSale = {
      ...sale,
      lines: newLines,
    } as Sale;

    return this.updateSale(newSale);
  }

  async updateSale(sale: Sale): Promise<Sale> {
    const totalAmount = sale.lines.reduce(
      (total, line) => total + line.price * line.quantity,
      0
    );

    const totalQuantity = sale.lines.reduce(
      (total, line) => total + line.quantity,
      0
    );

    const updatedSale = {
      ...sale,
      totalAmount,
      totalQuantity,
    } as Sale;

    return this.saleRepository.upsert(updatedSale);
  }

  async replaceLine(sale: Sale, line: SaleLine): Promise<Sale> {
    const newLines = [];

    for (const next of sale.lines) {
      if (next.id === line.id) {
        newLines.push(line);
      } else {
        newLines.push(next);
      }
    }

    const newSale = {
      ...sale,
      lines: newLines,
    } as Sale;

    return this.updateSale(newSale);
  }

  async deleteLine(sale: Sale, lineId: string): Promise<Sale> {
    const newLines = sale.lines.filter((line) => line.id !== lineId);

    const newSale = {
      ...sale,
      lines: newLines,
    } as Sale;

    return this.updateSale(newSale);
  }

  async clearLines(sale: Sale): Promise<Sale> {
    const newSale = {
      ...sale,
      lines: [],
    } as Sale;

    return this.updateSale(newSale);
  }

  deleteSale(sale: Sale) {
    return this.saleRepository.delete(sale);
  }

  async findAllSales(): Promise<Sale[]> {
    return await this.saleRepository.findAll();
  }

  async deleteOpenSales(): Promise<Sale[]> {
    const sales = await this.saleRepository.findByStatus("open");

    for (const sale of sales) {
      await this.saleRepository.delete(sale);
    }

    return this.saleRepository.findAll();
  }

  async uploadSale(sale: Sale): Promise<Sale> {
    if (!sale.closed) {
      sale = {
        ...sale,
        closed: moment().unix(),
      };
    }

    try {
      const uploadedSale = await this.client.uploadSale(sale);

      return { ...uploadedSale, status: "uploaded" };
    } catch (e: any) {
      console.error("error uploading sale: " + e.message);
      return { ...sale, errorMessage: e.message, status: "closed" };
    }
  }
}
