import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "reflect-metadata";
import App from "./App";
import { updateAvailable } from "./app/app.slice";
import { persistor, store } from "./app/store";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
import { PersistGate } from "redux-persist/integration/react";

PouchDB.plugin(PouchDBFind);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

serviceWorkerRegistration
  .register({
    onUpdate: () => {
      store.dispatch(updateAvailable());
    },
  })
  .then(() => {
    navigator.serviceWorker.ready.then((registration) => {
      setInterval(() => {
        registration.update();
      }, 1000 * 10 * 1);

      registration.update().then(() => {
        if (registration.waiting != null) {
          store.dispatch(updateAvailable());
        }
      });
    });
  });
