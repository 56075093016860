import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectOpenSale, setActiveSale } from "../sale/sale.slice";
import { NavbarLink } from "./NavbarLink";

export const TopNavigation = (): JSX.Element => {
  const openSale = useAppSelector(selectOpenSale);
  const dispatch = useAppDispatch();

  const handleOnClickBrowse = () => {
    dispatch(setActiveSale(openSale!._id));
  };

  return (
    <div>
      <div className="flex h-[72px] items-center gap-4 mb-4">
        <NavbarLink
          className="!self-stretch !flex-1 !grow"
          content=""
          to="/error"
        />
        <NavbarLink
          className="!self-stretch !flex-1 !grow"
          active
          content="Browse"
          to="/"
          onClick={() => {
            handleOnClickBrowse();
          }}
        />
        <NavbarLink
          className="!self-stretch !flex-1 !grow"
          content=""
          to="/erro"
        />
        <NavbarLink
          className="!self-stretch !flex-1 !grow"
          content="List"
          to="/list"
        />
      </div>
    </div>
  );
};
