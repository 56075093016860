import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import createMigrate from "redux-persist/es/createMigrate";
import storage from "redux-persist/lib/storage";
import saleReducer from "../sale/sale.slice";
import appReducer from "./app.slice";
import { listenerMiddleware } from "./listenerMiddleware";

const migrations = {
  3: (state: any) => {
    delete state?.app;
    delete state?.sale;

    return state;
  },
  4: (state: any) => {
    delete state?.app;

    return state;
  },
};

const persistConfig = {
  key: "root",
  storage: storage,
  version: 4,
  migrate: createMigrate(migrations),
  blacklist: ['app']
};

const reducers = combineReducers({
  app: appReducer,
  sale: saleReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }).prepend(listenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
