import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import UpdateDialog from "../app/UpdateDialog";
import EditLineDialog from "../edit/EditLineDialog";
import { TopNavigation } from "../nav/TopNavigation";
import { SalePage } from "../sale/SalePage";
import { useResponsive } from "../theme/hooks/useResponsive";
import ClosedSaleIndicator from "./ClosedSaleIndicator";
import UpdateIndicator from "./UpdateIndicator";
import { useState } from "react";
import { GoPackage } from "react-icons/go";
import { IoReceiptOutline } from "react-icons/io5";

import { MdListAlt } from "react-icons/md";
import { BrowsePage } from "../browse/BrowsePage";
import SaleListPage from "../sale/SaleListPage";

function Layout(props: any) {
  const isDesktop = useResponsive();
  const [bottomNavValue, setBottomNavValue] = useState(0);

  return (
    <div>
      <EditLineDialog />
      <UpdateDialog />

      <div className="flex pt-2 items-center px-6">
        <UpdateIndicator />
        <div className="flex grow"></div>
        <ClosedSaleIndicator setBottomNavValue={setBottomNavValue} />
      </div>

      {/* DESKTOP */}
      {isDesktop && (
        <div className="flex px-4 pt-2 pb-4">
          <div className="flex-1">
            <SalePage />
          </div>
          <div className="flex-1">
            <TopNavigation />

            <div className="mx-4 sm:mx-0">
              <main>{props.children}</main>
            </div>
          </div>
        </div>
      )}

      {/* MOBILE */}
      {!isDesktop && (
        <div>
          <div className="px-2 pt-2 pb-[100px]">
            {bottomNavValue === 0 && <SalePage />}
            {bottomNavValue === 1 && (
              <BrowsePage setBottomNavValue={setBottomNavValue} />
            )}
            {bottomNavValue === 2 && (
              <SaleListPage setBottomNavValue={setBottomNavValue} />
            )}
          </div>
          <Paper
            sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
            elevation={3}
          >
            <div className="bg-white py-2">
              <BottomNavigation
                showLabels
                value={bottomNavValue}
                onChange={(event, newValue) => {
                  setBottomNavValue(newValue);
                }}
              >
                <BottomNavigationAction
                  label="Receipt"
                  icon={<IoReceiptOutline size={24} />}
                />
                <BottomNavigationAction
                  label="Products"
                  icon={<GoPackage size={24} />}
                />

                <BottomNavigationAction
                  label="Sales"
                  icon={<MdListAlt size={24} />}
                />
              </BottomNavigation>
            </div>
          </Paper>
        </div>
      )}
    </div>
  );
}

export default Layout;
