import PouchDB from "pouchdb";
import { Entity } from "../model/entity";

export abstract class Repository<T extends Entity = Entity> {
  protected db: PouchDB.Database<T>;
  private name: string;

  constructor(name: string) {
    this.name = name;
    this.db = new PouchDB(this.name);
  }

  async upsert(entity: T): Promise<T> {
    await this.db.put(entity, { force: true });

    const doc = await this.find(entity._id);

    if (!doc) {
      throw new Error("Failed to save entity");
    }

    return doc!;
  }

  async insert(entity: T): Promise<T> {
    await this.db.post(entity);

    const doc = await this.find(entity._id);

    if (!doc) {
      throw new Error("Failed to save entity");
    }

    return doc!;
  }

  async findAll(): Promise<T[]> {
    const response = await this.db.allDocs<T>({ include_docs: true });

    return response.rows.map((row) => row.doc as T);
  }

  async find(id: string): Promise<T | null> {
    try {
      return await this.db.get(id);
    } catch (err: any) {
      if (err.name === "not_found") {
        return null;
      }

      throw err;
    }
  }

  async clear() {
    await this.db.destroy();
    this.db = new PouchDB(this.name);
  }

  async delete(entity: T) {
    await this.db.remove({
      _id: entity._id,
      _rev: entity._rev!,
    });
  }
}
