import { Dialog, SwipeableDrawer } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { SaleLine } from "../db/model/sale-line";
import {
  clearActiveLine,
  deleteSaleLine,
  selectActiveLine,
  updateSaleLine,
} from "../sale/sale.slice";
import KeyPad, {
  editLineLayout,
  updateAmount,
  updateQuantity,
} from "../theme/KeyPad";
import { useResponsive } from "../theme/hooks/useResponsive";

export default function EditLineDialog() {
  const line = useAppSelector(selectActiveLine);
  const isDesktop = useResponsive();

  const [draftLine, setDraftLine] = useState(line);

  useEffect(() => {
    setDraftLine(line);
  }, [line]);

  const open = !!line;

  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(clearActiveLine());
  };

  const onSave = async () => {
    if (draftLine!.quantity === 0) {
      dispatch(deleteSaleLine(draftLine?.id));
    } else {
      dispatch(updateSaleLine(draftLine!));
      dispatch(clearActiveLine());
    }
  };

  const handleOnKey = (key: string | number, mode: string) => {
    if (typeof key === "number" || key === "Backspace") {
      switch (mode) {
        case "Quantity":
          setDraftLine({
            ...draftLine!,
            quantity: updateQuantity(draftLine!.quantity, key),
          });
          break;
        case "Price":
          setDraftLine({
            ...draftLine!,
            price: updateAmount(draftLine!.price, key),
          });
          break;
      }
    }

    switch (key) {
      case "Enter":
        onSave();
        break;
      case "+":
        switch (mode) {
          case "Quantity":
            incQuantity(draftLine!, setDraftLine);
            break;
          case "Price":
            incPrice(draftLine!, setDraftLine);
            break;
        }
        break;
      case "-":
        switch (mode) {
          case "Quantity":
            decQuantity(draftLine!, setDraftLine);
            break;
          case "Price":
            decPrice(draftLine!, setDraftLine);
            break;
        }
        break;
    }
  };
  if (!isDesktop) {
    return (
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        onOpen={() => {}}
      >
        <div>
          <div className="flex font-mono px-4 text-sm lg:text-lg py-6 border-b-2">
            <div className="flex grow justify-start">
              {draftLine?.product.name}
            </div>
            <div className="flex grow justify-end px-12">
              {draftLine?.quantity}
            </div>
            <div className="flex justify-end">
              {draftLine?.price?.toFixed(2)}
            </div>
          </div>
          <KeyPad onKey={handleOnKey} layout={editLineLayout} />
        </div>
      </SwipeableDrawer>
    );
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div>
        <div className="flex font-mono px-4 text-sm lg:text-lg py-6 border-b-2">
          <div className="flex grow justify-start">
            {draftLine?.product.name}
          </div>
          <div className="flex grow justify-end px-12">
            {draftLine?.quantity}
          </div>
          <div className="flex justify-end">{draftLine?.price?.toFixed(2)}</div>
        </div>
        <div className="flex">
          <KeyPad onKey={handleOnKey} layout={editLineLayout} />
        </div>
      </div>
    </Dialog>
  );
}

function incQuantity(
  draftLine: SaleLine,
  setDraftLine: (line: SaleLine) => void
) {
  setDraftLine({
    ...draftLine!,
    quantity: draftLine!.quantity + 1,
  });
}

function decQuantity(
  draftLine: SaleLine,
  setDraftLine: (line: SaleLine) => void
) {
  if (draftLine!.quantity === 0) return;

  setDraftLine({
    ...draftLine!,
    quantity: draftLine!.quantity - 1,
  });
}

function incPrice(draftLine: SaleLine, setDraftLine: (line: SaleLine) => void) {
  setDraftLine({
    ...draftLine!,
    price: draftLine!.price + 1,
  });
}

function decPrice(draftLine: SaleLine, setDraftLine: (line: SaleLine) => void) {
  if (draftLine!.price < 1) return;

  setDraftLine({
    ...draftLine!,
    price: draftLine!.price - 1,
  });
}
