import { singleton } from "tsyringe";
import { Repository } from "./repository";
import { Product } from "../model/product";

@singleton()
export class ProductRepository extends Repository<Product> {
  constructor() {
    super("products");
  }
}
