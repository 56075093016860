import classNames from "classnames";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { SaleLine } from "../db/model/sale-line";
import LoadingSpinner from "../theme/LoadingSpinner";
import CloseSaleDialog from "./CloseSaleDialog";
import SaleHeader from "./SaleHeader";
import { selectActiveSale, setActiveLine } from "./sale.slice";
import { useResponsive } from "../theme/hooks/useResponsive";

export const SalePage = (): JSX.Element => {
  const [timestamp, setTimestamp] = useState(new Date());
  const [payCashOpen, setPayCashOpen] = useState(false);
  const dispatch = useAppDispatch();
  const sale = useAppSelector(selectActiveSale);
  const isDesktop = useResponsive();

  useEffect(() => {
    const interval = setInterval(() => setTimestamp(new Date()), 1000);

    return () => clearInterval(interval);
  }, []);

  if (!sale) {
    return (
      <div className="flex justify-center pt-10">
        <LoadingSpinner />
      </div>
    );
  }

  const handlePayCardClick = async () => {};

  const handlePayCashClick = async () => {
    if (sale.lines.length === 0) {
      return;
    }

    setPayCashOpen(true);
  };

  const handleLineClick = (line: SaleLine) => {
    if (sale.status === "open") {
      dispatch(setActiveLine(line.id));
    }
  };

  return (
    <div className="flex flex-col gap-4 lg:mr-4">
      <SaleHeader />

      <div className="bg-white p-2 lg:p-4">
        <div className="text-green-500 font-bold">
          {timestamp.toLocaleTimeString()}
        </div>
        <div
          className={classNames("flex mt-6 gap-4", {
            hidden: sale.status !== "open",
          })}
        >
          {isDesktop && <div className="grow"></div>}
          <button
            disabled={true}
            className={classNames(
              "bg-green-500 lg:font-lg py-2 px-10 uppercase",
              {
                "grow": !isDesktop,
                "bg-slate-300": true,
              }
            )}
            onClick={() => handlePayCardClick()}
          >
            {isDesktop ? "Pay Card" : "Card"}
          </button>
          <button
            disabled={sale.lines.length === 0}
            className={classNames("bg-green-500 font-lg py-2 px-10 uppercase", {
              "grow": !isDesktop,
              "bg-slate-300": sale.lines.length === 0,
              "bg-green-500 text-white": sale.lines.length > 0,
            })}
            onClick={() => handlePayCashClick()}
          >
            {isDesktop ? "Pay Cash" : "Cash"}
          </button>
        </div>
        {sale.lines.length === 0 && (
          <div className="flex border-y-2 mt-4 justify-center py-4 text-xl lg:text-3xl text-slate-300 font-mono">
            New Receipt
          </div>
        )}
        <div className="flex flex-col mt-4">
          {sale.lines.map((line) => (
            <button
              disabled={sale.status !== "open"}
              key={line.id}
              className="flex grow font-mono px-4 text-sm lg:text-lg py-6 border-b-2"
              onClick={() => handleLineClick(line)}
            >
              <div className="flex grow justify-start text-start">{line.product.name}</div>
              <div className="flex justify-end text-end px-2 lg:px-12">{line.quantity}</div>
              <div className="flex justify-end text-end w-[100px] lg:w-[140px]">
                {line.price?.toFixed(2)}
              </div>
            </button>
          ))}
        </div>
        <div className="flex font-mono mt-12 px-4 text-sm lg:text-lg ">
          <div className="flex grow justify-start text-start">TOTAL</div>
          <div className="flex px-2 justify-end text-end px-2 lg:px-12">
            {sale.totalQuantity}
          </div>
          <div className="flex justify-end text-end w-[100px] lg:w-[140px]">
            {sale.totalAmount.toFixed(2)}
          </div>
        </div>
        <div className="mt-10 border-y-2 lg:h-[100px]"></div>
      </div>
      <CloseSaleDialog open={payCashOpen} setOpen={setPayCashOpen} />
    </div>
  );
};
