import { selectUpdateAvailable } from "../app/app.slice";
import { useAppSelector } from "../app/hooks";


export default function UpdateIndicator() {
  const version = process.env.REACT_APP_VERSION;
  const updateAvailable = useAppSelector(selectUpdateAvailable);

  const handleUpdateClick = async () => {
    const registration = await navigator.serviceWorker.getRegistration();

    registration?.waiting?.postMessage({ type: "SKIP_WAITING" });

    window.location.reload();
  };
  

  return (
    <div>
      <div className="text-white text-xs py-auto">Version: {version}</div>
      {updateAvailable && (
        <div>
          <button
            className="text-sm text-white bg-blue-500 px-6 py-1 uppercase font-bold"
            onClick={() => handleUpdateClick()}
          >
            Update
          </button>
        </div>
      )}
    </div>
  );
}
