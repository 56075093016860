import { useAppSelector } from "../app/hooks";
import SaleTile from "./SaleTile";
import UploadButton from "./UploadButton";
import { selectClosedSales } from "./sale.slice";

type Props = {
  setBottomNavValue?: (value: number) => void;
};

export default function SaleListPage({ setBottomNavValue }: Props) {
  const sales = useAppSelector(selectClosedSales);

  return (
    <div className="flex flex-col bg-white p-4 gap-y-4">
      <div className="flex border-b-2 pb-2">
        <div className="text-2xl pt-1 flex grow">Closed Sales</div>
        <UploadButton />
      </div>
      <div className="flex flex-col">
        {sales.map((sale) => (
          <SaleTile
            sale={sale}
            key={sale._id}
            setBottomNavValue={setBottomNavValue}
          />
        ))}
      </div>
    </div>
  );
}
