import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useEffect, useState } from "react";
import { selectUpdateAvailable } from "./app.slice";
import { useAppSelector } from "./hooks";

export default function UpdateDialog() {
  const updateAvailable = useAppSelector(selectUpdateAvailable);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (updateAvailable) {
      setOpen(true);
    }
  }, [updateAvailable]);

  const onClose = () => {
    setOpen(false);
  };

  const handleUpdateClick = async () => {
    const registration = await navigator.serviceWorker.getRegistration();

    registration?.waiting?.postMessage({ type: "SKIP_WAITING" });

    window.location.reload();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>New Version Available</DialogTitle>
      <DialogContent>
        <p>
          A new version of this application is available, click UPDATE below to
          update now.
        </p>
      </DialogContent>
      <DialogActions>
        <button
          className="bg-slate-200 rounded uppercase py-2 px-8 mx-4 mb-2"
          onClick={() => onClose()}
        >
          LATER
        </button>
        <button
          className="bg-blue-500 rounded text-white uppercase py-2 px-6 mx-4 mb-2"
          onClick={() => handleUpdateClick()}
        >
          UPDATE NOW
        </button>
      </DialogActions>
    </Dialog>
  );
}
