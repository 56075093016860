import { singleton } from "tsyringe";
import { Sale } from "../model/sale";
import { Repository } from "./repository";

@singleton()
export class SaleRepository extends Repository<Sale> {
  constructor() {
    super("sales");
  }

  async findByStatus(status: Sale["status"]): Promise<Sale[]> {
    const response = await this.db.find({
      selector: {
        status,
      },
    });

    const entities = response.docs.map((doc) => doc as any as Sale);

    entities.sort(compareFn);

    return response.docs.map((doc) => doc as any as Sale);
  }
}

function compareFn(a: Sale, b: Sale) {
  if (a.created === b.created) {
    return 0;
  }

  if (a.created < b.created) {
    return -1;
  }

  return 1;
}
