import { Dialog, DialogTitle } from "@mui/material";
import { useState } from "react";
import { useAppDispatch } from "../app/hooks";
import KeyPad, { editAmountLayout, updateAmount } from "../theme/KeyPad";
import { closeActiveSale } from "./sale.slice";
import { useResponsive } from "../theme/hooks/useResponsive";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function CloseSaleDialog({ open, setOpen }: Props) {
  const [amount, setAmount] = useState(0);
  const dispatch = useAppDispatch();
  const isDesktop = useResponsive();

  const onClose = () => {
    setAmount(0);
    setOpen(false);
  };

  const handleEnterClick = async () => {
    onClose();
    dispatch(closeActiveSale());
  };

  const handleOnKey = (key: string | number, mode: string) => {
    if (typeof key === "number" || key === "Backspace") {
      setAmount(updateAmount(amount, key));
    }

    if (key === "Enter") {
      handleEnterClick();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      {isDesktop && <DialogTitle>Cash Received</DialogTitle>}
      <div>
        <div className="flex font-mono px-4 text-sm lg:text-lg py-6 border-b-2 justify-end">
          <div className="flex justify-end">{amount.toFixed(2)}</div>
        </div>
        <KeyPad onKey={handleOnKey} layout={editAmountLayout} />
      </div>
    </Dialog>
  );
}
