import { Entity } from "./entity";
import { SaleLine } from "./sale-line";
import moment from "moment";

export interface Sale extends Entity {
  readonly closed: number | null;
  readonly created: number;
  readonly lines: SaleLine[];
  readonly paymentMethod: string;
  readonly status: "open" | "closed" | "uploaded" | "uploading";
  readonly totalAmount: number;
  readonly totalQuantity: number;
  readonly errorMessage?: string;
}

export const saleTemplate: () => Sale = () => {
  return {
    _id: crypto.randomUUID(),
    closed: null,
    created: moment().unix(),
    lines: [],
    paymentMethod: "CASH",
    status: "open",
    totalAmount: 0,
    totalQuantity: 0,
  };
};
