import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { container } from "tsyringe";
import { Category } from "../db/model/category";
import { Product } from "../db/model/product";
import { ProductService } from "../product/product.service";
import { addSaleLine } from "../sale/sale.slice";
import LoadingSpinner from "../theme/LoadingSpinner";
import { CategoryTile } from "./CategoryTile";
import { ProductTile } from "./ProductTile";
import { useResponsive } from "../theme/hooks/useResponsive";
import { IoArrowBackCircle } from "react-icons/io5";
import classNames from "classnames";

interface Props {
  setBottomNavValue?: (value: number) => void;
}

export const BrowsePage = ({ setBottomNavValue }: Props): JSX.Element => {
  const [products, setProducts] = useState([] as Product[]);
  const [categories, setCategories] = useState([] as Category[]);
  const [busy, setBusy] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const productService = useMemo(() => container.resolve(ProductService), []);
  const dispatch = useDispatch();
  const isDesktop = useResponsive();

  // TODO: gbs - move products into redux
  useEffect(() => {
    (async () => {
      await productService.getProducts().then(([products, categories]) => {
        if (products.length === 0) {
          setBusy(true);
        }

        setProducts(products);
        setCategories(categories);
      });

      try {
        await productService
          .downloadProducts()
          .then(([products, categories]) => {
            setProducts(products);
            setCategories(categories);

            setBusy(false);
          });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [productService]);

  const handleCategoryClick = (category: Category) => {
    setSelectedCategory(category);
  };

  const handleProductClick = async (product: Product) => {
    dispatch(addSaleLine(product));
    if (!!setBottomNavValue) {
      setBottomNavValue(0);
    }
  };

  const handleBackClicked = () => {
    setSelectedCategory(undefined);
  };

  let productsElement = <LoadingSpinner />;

  let categoriesElement = <LoadingSpinner />;

  if (!busy) {
    productsElement = (
      <div className="flex flex-col gap-y-4">
        <div
          className={classNames(
            "flex items-center gap-x-2 text-xl font-bold border-b-2 pb-1",
            {
              "cursor-pointer": !isDesktop,
            }
          )}
          onClick={() => handleBackClicked()}
        >
          {!isDesktop && <IoArrowBackCircle size={22} />}
          {selectedCategory?.name ?? "All"}
        </div>
        {products
          .filter(
            (product) =>
              !!product.price &&
              (selectedCategory?._id === product.categoryId ||
                !selectedCategory)
          )
          .map((product) => (
            <div
              key={product._id}
              className="cursor-pointer"
              onClick={() => handleProductClick(product)}
            >
              <ProductTile product={product} />
            </div>
          ))}
      </div>
    );

    categoriesElement = (
      <div className="flex flex-col gap-y-4">
        <div className="text-xl font-bold border-b-2 pb-1">Categories</div>
        {categories.map((category) => (
          <div
            key={category._id}
            className="cursor-pointer"
            onClick={() => handleCategoryClick(category)}
          >
            <CategoryTile category={category} />
          </div>
        ))}
      </div>
    );
  }
  if (isDesktop) {
    return (
      <div className="flex gap-x-4">
        <div className="flex-1 bg-white p-4">{productsElement}</div>
        <div className="flex-1 bg-white p-4">{categoriesElement}</div>
      </div>
    );
  }

  return (
    <div>
      {!selectedCategory && (
        <div className="bg-white p-4">{categoriesElement}</div>
      )}
      {selectedCategory != null && (
        <div className="bg-white p-4">{productsElement}</div>
      )}
    </div>
  );
};
