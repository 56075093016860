import classNames from "classnames";
import { useState } from "react";
import { FaBackspace } from "react-icons/fa";

type Props = {
  onKey: (key: string | number, mode: string) => void;
  layout: Layout;
};
interface Layout {
  cols: string;
  keys: LayoutKey[];
}
type LayoutKey = string | number | [string | number, number];

export default function KeyPad({ onKey, layout }: Props) {
  const [mode, setMode] = useState("Quantity");

  const handleOnKey = (key: any) => {
    switch (key) {
      case "Quantity":
      case "Price":
      case "Discount":
        setMode(key);
        break;
      default:
        onKey(key, mode);
    }
  };

  return (
    <div>
      <div className={`grid ${layout.cols} gap-2 m-2`}>
        {layout.keys.map((config, index) => {
          let key: any, span;

          if (Array.isArray(config)) {
            [key, span] = config;
          } else {
            key = config;
            span = 1;
          }

          return (
            <button
              key={"keypad-" + index + "-" + key}
              className={classNames(
                "bg-gray-200 text-gray-800 h-[45px] lg:h-[75px] text-sm lg:text-lg font-bold uppercase pointer-cursor items-center justify-center flex",
                {
                  "col-span-2": span === 2,
                  "col-span-3": span === 3,
                  "bg-green-500": key === "Enter",
                  "bg-violet-300": key === mode,
                }
              )}
              onClick={() => handleOnKey(key)}
            >
              <RenderKey keyValue={key} />
            </button>
          );
        })}
      </div>
    </div>
  );
}

function RenderKey({ keyValue }: { keyValue: string }) {
  if (keyValue === "Backspace") {
    return <FaBackspace size={24} />;
  }
  return <div>{keyValue}</div>;
}

export const editLineLayout: Layout = {
  cols: "grid-cols-[60px_60px_60px_60px_60px] lg:grid-cols-[100px_100px_100px_100px_100px]",
  keys: [
    "",
    "",
    "",
    "-",
    "+",
    7,
    8,
    9,
    ["Quantity", 2],
    4,
    5,
    6,
    ["Price", 2],
    1,
    2,
    3,
    ["", 2],
    [0, 2],
    "Backspace",
    ["Enter", 2],
  ],
};

export const editAmountLayout: Layout = {
  cols: "grid-cols-[60px_60px_60px] lg:grid-cols-[100px_100px_100px]",
  keys: [7, 8, 9, 4, 5, 6, 1, 2, 3, [0, 2], "Backspace", ["Enter", 3]],
};

export function updateAmount(amount: number, key: string | number): number {
  amount *= 100;
  if (key === "Backspace") {
    if (amount < 10) {
      return 0;
    }
    return Math.floor(amount / 10) / 100;
  }

  if (typeof key === "number") {
    return (amount * 10 + Number(key)) / 100;
  }

  return amount;
}

export function updateQuantity(quantity: number, key: string | number): number {
  if (key === "Backspace") {
    if (quantity < 10) {
      return 0;
    }
    return Math.floor(quantity / 10);
  }

  if (typeof key === "number") {
    return quantity * 10 + Number(key);
  }

  return quantity;
}
