import { FaAsterisk } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { VscTriangleDown } from "react-icons/vsc";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  deleteAllSaleLines,
  deleteSale,
  selectActiveSale,
  startNewSale,
} from "./sale.slice";
import { useResponsive } from "../theme/hooks/useResponsive";
import classNames from "classnames";

export default function SaleHeader() {
  const dispatch = useAppDispatch();
  const sale = useAppSelector(selectActiveSale);
  const isDesktop = useResponsive();

  const handleNewClick = async () => {
    dispatch(startNewSale());
  };

  const handleDeleteClick = async () => {
    switch (sale?.status) {
      case "open":
        dispatch(deleteAllSaleLines());
        break;
      default:
        dispatch(deleteSale(sale!._id));
        break;
    }
  };

  return (
    <div className={`flex h-[48px] lg:h-[72px] items-center gap-2 lg:gap-4`}>
      <div
        className={classNames(
          `self-stretch w-[72px] bg-green-500 flex items-center justify-center`,
          {
            hidden: !isDesktop,
          }
        )}
      >
        <VscTriangleDown color="white" size={32} />
      </div>
      <button
        className={`self-stretch w-[72px] lg:w-[100px] bg-green-500 flex items-center justify-center`}
        onClick={() => handleNewClick()}
      >
        <FaAsterisk color="white" size={isDesktop ? 24 : 18} />
      </button>
      <button
        className={`self-stretch w-[72px] lg:w-[100px] bg-green-500 flex items-center justify-center`}
        onClick={() => handleDeleteClick()}
      >
        <MdDelete color="white" size={isDesktop ? 32 : 24} />
      </button>
      <div className="self-stretch grow bg-slate-400 flex items-center justify-center">
        <div className="text-white text-xl lg:text-3xl font-bold font-mono">
          {sale?.totalAmount.toFixed(2) ?? "0.00"}
        </div>
      </div>
    </div>
  );
}
