import { container, injectable } from "tsyringe";
import { PredictivClient } from "../predictiv/predictiv-client";
import { Product } from "../db/model/product";
import { Category } from "../db/model/category";
import { ProductRepository } from "../db/repository/product.repository";
import { CategoryRepository } from "../db/repository/category.repository";

@injectable()
export class ProductService {
  private predictivClient: PredictivClient;
  private productRepository: ProductRepository;
  private categoryRepository: CategoryRepository;

  constructor() {
    this.predictivClient = container.resolve(PredictivClient);
    this.productRepository = container.resolve(ProductRepository);
    this.categoryRepository = container.resolve(CategoryRepository);
  }

  async getProducts(): Promise<[Product[], Category[]]> {
    const products = await this.productRepository.findAll();
    const categories = await this.categoryRepository.findAll();

    products.sort((a, b) => a.name.localeCompare(b.name));
    categories.sort((a, b) => a.name.localeCompare(b.name));

    return [products, categories];
  }

  async downloadProducts(): Promise<[Product[], Category[]]> {
    const productDtos = await this.predictivClient.getProducts();

    const priceList = await this.predictivClient.getPricelist();

    const products = [] as Product[];
    const categories = [] as Category[];

    await this.productRepository.clear();
    await this.categoryRepository.clear();

    for (const productDto of productDtos) {
      const priceListEntry = priceList.find(
        (priceListEntry) => priceListEntry["product"] === productDto["id"]
      );

      if (!priceListEntry) {
        continue;
      }

      productDto["price"] = priceListEntry["listPrice"];

      let product = this.parseProduct(productDto);

      products.push(product);

      const existingProduct = await this.productRepository.find(product._id);

      if (existingProduct) {
        product = {
          ...existingProduct,
          ...product,
        };
      }

      await this.productRepository.upsert(product);

      const category = categories.find(
        (category) => category._id === product.categoryId
      );

      if (!category) {
        let newCategory = {
          _id: product.categoryId,
          name: product.categoryName,
        } as Category;

        categories.push(newCategory);

        const existingCategory = await this.categoryRepository.find(
          newCategory._id
        );

        if (existingCategory) {
          newCategory = {
            ...existingCategory,
            ...newCategory,
          };
        }

        await this.categoryRepository.insert(newCategory);
      }
    }

    products.sort((a, b) => a.name.localeCompare(b.name));

    categories.sort((a, b) => a.name.localeCompare(b.name));

    return [products, categories];
  }

  parseProduct(json: any): Product {
    return {
      _id: json["id"],
      name: json["name"],
      categoryId: json["productCategory"],
      categoryName: json["productCategory$_identifier"],
      price: json["price"],
      uom: json["uOM"],
    };
  }
}
